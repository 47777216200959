import "./App.scss";
import Header from "./components/Header/Header";
import Services from "./components/Services";
import Valeurs from "./components/Valeurs";
import Equipe from "./components/Equipe";
import Footer from "./components/Footer/Footer";
import Cookies from "./components/Cookies";

function App() {
  return (
    <div className="App">
      <Header></Header>
      <main>
        <section
          id={"accueil"}
          className={"hero_section filter_black"}
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/img/Equipe.jpg')`,
          }}
        >
          <div
            style={{
              display: "flex",
              position: "fixed",
              bottom: 0,
              zIndex: 10,
              right: 50,
            }}
          >
            {<Cookies></Cookies>}
          </div>
          <div className="container" style={{ marginTop: "15em" }}>
            <h1>Bienvenue dans le cabinet financier Plani-Concept!</h1>
          </div>
        </section>
        <section id={"services"}>
          <div className="container">
            <h2>Nos services</h2>
            <div className="services_container">{<Services></Services>}</div>
          </div>
        </section>
        <section id={"valeurs"}>
          <div className="container">
            <h2>Nos valeurs</h2>
            <Valeurs></Valeurs>
          </div>
        </section>
        <section id={"mission"}>
          <div className="container">
            {/*<div className="col-md-8 offset-md-2">*/}
            <h2>Notre engagement</h2>
            <p className="lead">
              <b>
                Devenir votre complice au fil du temps, pour le maintien de
                votre équilibre financier.
              </b>
            </p>
            {/*</div>*/}
          </div>
        </section>
        <section id={"equipe"}>
          <div className="container">
            <h2>Notre Équipe</h2>
            <Equipe></Equipe>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default App;
