import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';

const Cookies = () => {
  const [showToast, setShowToast] = useState(true);

  const closeToast = () => setShowToast(false);

  return (
    <Toast show={showToast} onClose={closeToast} bg="info">
      <Toast.Header>
        <small className="me-auto flash">Avis important</small>
      </Toast.Header>
      <Toast.Body className="text-white">
        Ce site web n'utilise aucun cookie
      </Toast.Body>
    </Toast>
  );
};

export default Cookies;